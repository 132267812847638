import React from "react";
import MerelEnDaan from "../files/MerelEnDaan.jpeg";

export default function Intro() {
  const stappen = [
    "Je voert ons afgesproken wachtwoord in",
    "Open een envelop op de dag dat hij open kan",
    "Streep een voucher af op het moment dat je hem gebruikt hebt",
  ];

  return (
    <div className="mt-8 md:mb-0 px-5 py-5 grid md:grid-cols-5 grid-cols-1 md:gap-10 bg-gray-50 rounded-md items-center">
      <div className="md:col-span-3 ">
        <h2 className="text-xl font-medium font">Wat is dit?</h2>
        <p className="mt-1">
          Omdat Merel (mijn vriendin) een lange tijd niet in Nederland is, moest
          ik opzoek naar een andere manier om haar te laten zien dat ik nog
          steeds{" "}
          <span className="border-b-2 border-pink-600 text-pink-600 font-bold">
            heel veel van haar houd
          </span>
          . Daarom heb ik liefdesjaar gemaakt; een app waar Merel voor een jaar
          lang iedere week een{" "}
          <span className="border-b-2 border-pink-600 text-pink-600 font-bold">
            envelopje uit kan pakken
          </span>
          , of ze nou in Nederland of in Costa Rica zit, met daarin iedere keer
          een nieuwe verassing.
          <br />
          <br />
          <span className=" font-medium">Liefdesjaar werkt als volgt:</span>
        </p>

        <div className=" space-y-1 mt-2">
          {stappen.map((el, key) => {
            return (
              <div className="flex">
                <p className="w-6 rounded shrink-0 h-6 bg-pink-600 font-bold text-white text-center mr-2">
                  {key + 1}
                </p>
                <p>{el}</p>
              </div>
            );
          })}
        </div>
      </div>
      <div className=" w-full justify-center flex md:w-min">
        <img
          className=" max-w-xs w-72 rounded-md md:col-span-2 rotate-1 hover:rotate-3 transition-all shadow-md hover:shadow-xl duration-300 md:translate-y-0 translate-y-10 md:translate-x-10"
          src={MerelEnDaan}
        ></img>
      </div>
    </div>
  );
}
