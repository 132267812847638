import { useState, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import moment from "moment";
import axios from "axios";
import Cookies from "universal-cookie";
import image from "../files/MerelEnDaan.jpeg";

function Modal(props) {
  let [isOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  function voucherGebruiken() {
    const cookie = new Cookies();
    if (cookie.get("auth") == "true") {
      console.log("Check!");
      let tijd = moment().format("YYYY-MM-DD HH:mm:ss");

      axios
        .post(
          `https://rpidaan.nl:3000/api/maakGebruikt?datum=${props.inhoud.datum}`
        )
        .then(() => {
          axios
            .post(
              `https://rpidaan.nl:3000/api/tijdGebruikt?tijd=${tijd}&datum=${props.inhoud.datum}`
            )
            .then(() => {
              window.location.reload();
            });
        });
    }
  }

  return (
    <>
      <svg
        onClick={openModal}
        xmlns="http://www.w3.org/2000/svg"
        className={`absolute ${
          props.clicked ? "opacity-0 vergrootIcoon cursor-pointer" : "hidden"
        } ${props.specialeDag ? "top-6 h-6 w-6 " : "top-4 h-5 w-5"}`}
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M4 8V4m0 0h4M4 4l5 5m11-1V4m0 0h-4m4 0l-5 5M4 16v4m0 0h4m-4 0l5-5m11 5l-5-5m5 5v-4m0 4h-4"
        />
      </svg>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={closeModal}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black opacity-40" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-xl p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <Dialog.Title
                  as="h3"
                  className="font-inter text-sm font-medium leading-6 text-gray-900"
                >
                  Week {props.inhoud.weekNummer} |{" "}
                  {moment(props.inhoud.datum).format("DD-MM-YY")}
                </Dialog.Title>
                <div className="mt-4 text-center flex flex-col items-center">
                  <div className="col-span-5 rounded-md bg-gray-50  py-4">
                    <p className="font-inter font-medium text-xl underline px-5 ">
                      {props.inhoud.text}
                    </p>
                  </div>
                </div>

                <div className="mt-4">
                  <table
                    className={`table-auto ${
                      props.inhoud.gebruikt && "w-full"
                    }`}
                  >
                    <tbody className="divide-y divide-gray-200">
                      <tr>
                        <td className="pr-5">Gebruikt</td>
                        <td>{props.inhoud.gebruikt ? "Ja" : "Nee"}</td>
                      </tr>
                      {props.inhoud.gebruikt ? (
                        <>
                          <tr>
                            <td className="pr-5">Datum</td>
                            <td>
                              {moment(props.inhoud.tijdGebruikt).format("LL")}
                            </td>
                          </tr>
                          <tr>
                            <td className="pr-5">Tijd</td>
                            <td>
                              {moment(props.inhoud.tijdGebruikt).format("LT")}
                            </td>
                          </tr>
                        </>
                      ) : (
                        <tr>
                          <td className="pr-5">Verstreken in</td>
                          <td>
                            {moment(props.inhoud.geldigTotDatum).fromNow(true)}
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>

                <div className="mt-10 md:flex flex-row-reverse md:flex-row md:justify-between space-y-2 md:space-y-0">
                  <button
                    type="button"
                    className={`w-full md:w-auto items-center px-4 py-2 text-sm font-medium bg-green-200 rounded-md text-green-900 hover:bg-green-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-50 ${
                      props.inhoud.gebruikt === 1 ? "hidden" : "md:inline-flex"
                    }`}
                    onClick={() => voucherGebruiken()}
                  >
                    Voucher gebruiken
                  </button>
                  {console.log(props.inhoud.gebruikt === 1)}
                  <button
                    onClick={closeModal}
                    type="button"
                    className="w-full md:w-auto md:inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-50"
                  >
                    Ga terug
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

export default Modal;
