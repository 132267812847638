import { useState, Fragment, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Authenticatie from "./Authenticatie";

function AuthModal(props) {
  let [isOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  useEffect(() => {
    if (props.clicked != 0) {
      setIsOpen(true);
    }
  }, [props.clicked]);

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={closeModal}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black opacity-5" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-lg p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <Dialog.Title
                  as="h3"
                  className="font-inter text-sm font-medium leading-6 text-gray-900"
                >
                  Authenticatie
                </Dialog.Title>
                <div className="mt-4 py-4 rounded-md">
                  <p className="font-inter font-medium text-xl">
                    Ohja, jij bent Merel? Nou laat maar eens zien. Voer ons
                    afgesproken wachtwoord in:
                  </p>
                </div>

                <div className="mt-2">
                  <div>
                    <label htmlFor="wachtwoord" className="sr-only">
                      Wachtwoord
                    </label>
                    <input
                      type="password"
                      name="password"
                      id="password"
                      className="shadow-sm px-4 py-2 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm  border border-gray-300 rounded-md"
                      placeholder="Wachtwoord"
                    />
                  </div>
                </div>

                <div className="mt-10 md:flex md:flex-row-reverse md:justify-between space-y-2 md:space-y-0">
                  <button
                    type="button"
                    className="w-full md:w-auto md:inline-flex items-center px-4 py-2 text-sm font-medium bg-green-200 rounded-md text-green-900 hover:bg-green-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-50"
                    onClick={() => {
                      Authenticatie(document.getElementById("password").value);
                      closeModal();
                      window.location.reload();
                    }}
                  >
                    Wachtwoord invoeren
                  </button>
                  <button
                    onClick={closeModal}
                    type="button"
                    className="w-full md:w-auto md:inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-50"
                  >
                    Ik ben toch Merel niet
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

export default AuthModal;
