import Cookies from "universal-cookie";

export default function Authenticatie(wachtwoordIngevoerd) {
  if (wachtwoordIngevoerd === "8g*RZR8z.VXLeV") {
    const cookies = new Cookies();
    cookies.set("auth", "true", {
      path: "/",
      expires: new Date(Date.now() + 2592000000),
    });
  }
}
