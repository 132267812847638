import Axios from "axios";
import moment from "moment";
import "moment/locale/nl";
import React, { useState, useEffect } from "react";
import AuthBalkje from "./AuthBalkje/AuthBalkje";
import ClosestDates from "./closestDates/ClosestDates";
import Envelop from "./Envelop/Envelop";
import Intro from "./Intro/Intro";

function App() {
  moment.locale("nl");

  const [envelop, setEnvelop] = useState([]);

  useEffect(() => {
    Axios.get("https://rpidaan.nl:3000/api/envelop").then((data) => {
      data.data.forEach((el) => {
        setEnvelop((envelop) => [...envelop, el]);
      });
    });
  }, []);

  return (
    <div className="App">
      <div className="mx-auto px-4 sm:px-6 lg:px-8">
        <div className="md:max-w-3xl mx-auto">
          <AuthBalkje />
          <header className="my-20 text-left">
            <p className="text-6xl font-garamond font-bold text-pink-600">
              liefdesjaar
            </p>
            {envelop.length > 0 && (
              <p className="font-inter font-normal pt-2">
                Nog{" "}
                <span className="font-bold">
                  {moment(ClosestDates(envelop)).fromNow(true)}
                </span>{" "}
                tot de volgende envelop open gaat...
              </p>
            )}

            <Intro />
          </header>

          <main>
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-8 grid-flow-row-dense my-10">
              {envelop.map((el, key) => {
                return (
                  <div
                    className={`${key} bg-gray-50 p-2 rounded-xl ${
                      el.specialeDag
                        ? "col-span-2 border-4 border-pink-600 bg-pink-50"
                        : ""
                    } relative grid place-items-center text-center`}
                  >
                    <div>
                      {el.specialeDag ? (
                        <h3 className=" font-inter font-semibold bg-pink-600 px-2 py-1 rounded-md text-md absolute -top-1 -left-1 text-white">
                          {el.specialeDag}
                        </h3>
                      ) : (
                        ""
                      )}
                      <Envelop
                        isGebruikt={el.gebruikt}
                        number={key}
                        specialeDag={el.specialeDag ? true : false}
                        inhoud={el}
                      />
                      <p className="font-inter font-medium text-xs text-gray-400">
                        {moment(el.datum).format("DD-MM-YY")}
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          </main>
        </div>
      </div>
    </div>
  );
}

export default App;
