import moment, { now } from "moment";

function ClosestDates(dates) {
  let nearestDate;

  if (dates.length < 1) {
    return "error";
  }

  dates.forEach((date) => {
    const datum = date.datum;
    let difference = moment(datum).diff(moment(), "days");

    if (difference > 0) {
      if (nearestDate) {
        if (moment(datum).diff(moment(nearestDate), "days") < 0) {
          nearestDate = datum;
        }
      } else {
        nearestDate = datum;
      }
    }
  });
  return nearestDate;
}

export default ClosestDates;
