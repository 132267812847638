import React from "react";
import { useEffect, useState } from "react";
import AuthModal from "./AuthModal";
const { default: Cookies } = require("universal-cookie/es6");

export default function AuthBalkje() {
  let [authClicked, setAuthClicked] = useState(0);

  let [show, setShow] = useState(true);

  const cookies = new Cookies();

  useEffect(() => {
    if (cookies.get("auth") == "true") {
      setShow(false);
    }
  }, []);

  return (
    <div
      className={`font-inter mt-5 text-center py-5 border-2 border-pink-600 bg-pink-50 border-dashed w-full ${
        show ? "block" : "hidden"
      }`}
    >
      <p>Alleen Merel kan wijzigingen doorvoeren!</p>
      <a
        onClick={() => setAuthClicked(authClicked + 1)}
        className="cursor-pointer underline"
      >
        Klik hier om als je Merel bent.
      </a>
      <AuthModal clicked={authClicked} />
    </div>
  );
}
